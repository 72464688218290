import './menu/menu';
import {calcPersonalProperty, calcCompanyProperty} from './calculator/calculator';

const slider = document.getElementById('range');
const slider2 = document.getElementById('range2');
const output = document.getElementById('rangeVal');
const output2 = document.getElementById('rangeVal2');
const total = document.getElementById('total');

// change type
window.changeType = function(cur, next) {
    var type = document.querySelectorAll('.calculator__type_step'); // Using a class instead, see note below.

    if (!type[cur].classList.contains('calculator__type_active')) {
        type[cur].classList.toggle('calculator__type_active');
        type[next].classList.toggle('calculator__type_active');

        var tooltip = document.querySelectorAll('.calculator__item_title span');
        slider.value = '1000';
        output.value = '1000';
        slider2.value = '1000';
        output2.value = '1000';

        if (type[0].classList.contains('calculator__type_active')) {
            total.innerHTML = calcPersonalProperty(1000) + ' ₽';
            tooltip[0].setAttribute(
                'tooltip',
                'Заявление имущественного характера подается в суд, если сумма иска для спора с участием граждан не превышает 100 000 ₽. Такое заявление подлежит рассмотрению судом в упрощенном порядке, без вызова сторон.',
            );
            tooltip[1].setAttribute(
                'tooltip',
                'Заявление о выдаче судебного приказа подается в суд, если сумма иска для спора с участием граждан не превышает 500 000 ₽. Если сумма иска не превышает данный лимит и есть документ, подтверждающий признание долга ответчиком (гарантийное письмо, акт сверки или иные), суд выносит судебный приказ, который одновременно является исполнительным листом – это ускоряет процедуру возврата денежных средств.',
            );
        } else {
            total.innerHTML = calcCompanyProperty(1000) + ' ₽';
            tooltip[0].setAttribute(
                'tooltip',
                'Заявление имущественного характера подается в суд, если сумма иска для спора с участием организаций не превышает 500 000 рублей, предпринимателей – 250 000 рублей. Такое заявление подлежит рассмотрению судом в упрощенном порядке, без вызова сторон.',
            );
            tooltip[1].setAttribute(
                'tooltip',
                'Заявление о выдаче судебного приказа подается в суд, если сумма иска для спора с участием организаций и предпринимателей не превышает 400 000 рублей. Если сумма иска не превышает данный лимит и есть документ, подтверждающий признание долга ответчиком (гарантийное письмо, акт сверки или иные), суд выносит судебный приказ, который одновременно является исполнительным листом – это ускоряет процедуру возврата денежных средств.',
            );
        }
    }
};

// change type
window.changeTypeOrder = (cur, next) => {
    var type = document.querySelectorAll('.calculator__type_step');
    var typeOrder = document.querySelectorAll('.calculator__item');

    if (!typeOrder[cur].classList.contains('calculator__item_active')) {
        typeOrder[cur].classList.toggle('calculator__item_active');
        typeOrder[next].classList.toggle('calculator__item_active');

        var val = document.querySelector('.calculator__item_active input');

        if (type[0].classList.contains('calculator__type_active')) {
            if (cur === 0) {
                total.innerHTML = calcPersonalProperty(val.value) + ' ₽';
            } else {
                total.innerHTML = calcPersonalProperty(val.value) / 2 + ' ₽';
            }
        } else {
            if (cur === 0) {
                total.innerHTML = calcCompanyProperty(val.value) + ' ₽';
            } else {
                total.innerHTML = calcCompanyProperty(val.value) / 2 + ' ₽';
            }
        }
    }
};
