var scrollIntoView = require('scroll-into-view');

var slider = document.getElementById('range');
var slider2 = document.getElementById('range2');
var output = document.getElementById('rangeVal');
var output2 = document.getElementById('rangeVal2');
var total = document.getElementById('total');

export function calcPersonalProperty(val) {
    var value = parseInt(val, 10);
    var percent = 4;
    var min = 400;
    var max = 60000;

    if (value <= 20000) {
        var result = (value * percent) / 100;

        if (result < min) {
            return min;
        } else {
            return result;
        }
    }

    if (value >= 20001 && value <= 100000) {
        var percent = 3;
        var min = 800;
        var value = value - 20000;
    } else if (value >= 100001 && value <= 200000) {
        var percent = 2;
        var min = 3200;
        var value = value - 100000;
    } else if (value >= 200001 && value <= 1000000) {
        var percent = 1;
        var min = 5200;
        var value = value - 200000;
    } else if (value >= 1000001) {
        var percent = 0.5;
        var min = 13200;
        var value = value - 1000000;
    }

    var result = (value * percent) / 100;

    if (result > max) {
        return max;
    }

    return result + min;
}

export function calcCompanyProperty(val) {
    var value = parseInt(val, 10);
    var percent = 4;
    var min = 2000;
    var max = 300000;

    if (value <= 100000) {
        var result = (value * percent) / 100;

        if (result < min) {
            return min;
        } else {
            return result;
        }
    }

    if (value >= 100001 && value <= 200000) {
        var percent = 3;
        var min = 4000;
        var value = value - 100000;
    } else if (value >= 200001 && value <= 1000000) {
        var percent = 2;
        var min = 7000;
        var value = value - 200000;
    } else if (value >= 1000001 && value <= 2000000) {
        var percent = 1;
        var min = 23000;
        var value = value - 1000000;
    } else if (value >= 2000001) {
        var percent = 0.5;
        var min = 33000;
        var value = value - 2000000;
    }

    var result = (value * percent) / 100;

    if (result > max) {
        return max;
    }

    return result + min;
}

slider.oninput = function() {
    var type = document.querySelectorAll('.calculator__type_step');
    output.value = this.value + ' ₽';

    if (type[0].classList.contains('calculator__type_active')) {
        total.innerHTML = calcPersonalProperty(this.value) + ' ₽';
    } else {
        total.innerHTML = calcCompanyProperty(this.value) + ' ₽';
    }
};

output.oninput = function() {
    var type = document.querySelectorAll('.calculator__type_step');
    if (type[0].classList.contains('calculator__type_active')) {
        total.innerHTML = calcPersonalProperty(this.value) + ' ₽';
    } else {
        total.innerHTML = calcCompanyProperty(this.value) + ' ₽';
    }
};

slider2.oninput = function() {
    var type = document.querySelectorAll('.calculator__type_step');
    output2.value = this.value + ' ₽';

    if (type[0].classList.contains('calculator__type_active')) {
        total.innerHTML = calcPersonalProperty(this.value) / 2 + ' ₽';
    } else {
        total.innerHTML = calcCompanyProperty(this.value) / 2 + ' ₽';
    }
};

output2.oninput = function(val) {
    var type = document.querySelectorAll('.calculator__type_step');

    if (type[0].classList.contains('calculator__type_active')) {
        total.innerHTML = calcPersonalProperty(this.value) / 2 + ' ₽';
    } else {
        total.innerHTML = calcCompanyProperty(this.value) / 2 + ' ₽';
    }
};

document.addEventListener(
    'click',
    function(event) {
        var hash = event.target.getAttribute('href');

        // If the clicked element doesn't have the right selector, bail
        if (!hash || hash[0] !== '#') return;

        // Don't follow the link
        event.preventDefault();

        if (document.querySelector('.hamburger').classList.contains('is-active')) {
            window.showMenu();
        }

        scrollIntoView(document.getElementById(hash.substring(1, hash.length)), {
            time: 500,
            align: {
                top: 0,
                topOffset: 100,
            },
        });
    },
    false,
);
